




export const AdminComponent = ({
    debug,
    /*calendarToggleHandler,
    adminToggleHandler,
    editFormToggleHandler,
    newFormToggleHandler,
    loginToggleHandler,*/
}) => {
    if(debug){console.log("AdminComponent started")}
    //if(1+1===20){adminToggleHandler();}
    return(
        <div>
            <div>
                <p>Admin Component</p>
            </div>
{/*            <div>
                <input 
                    type="button" 
                    onClick={calendarToggleHandler}
                    value="toggle calendar visibility"
                />
            </div>
            
            <div>
                <input 
                    type="button" 
                    onClick={loginToggleHandler}
                    value="toggle Login Component visibility"
                />
            </div>

            <div>
                <input 
                    type="button" 
                    onClick={editFormToggleHandler}
                    value="toggle Edit Item Form visibility"
                />
            </div>

            <div>
                <input 
                    type="button" 
                    onClick={newFormToggleHandler}
                    value="toggle New Item Form visibility"
                />
            </div>*/}
        </div>
    );
}