import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//Get the full URL for the purpose of parsing URL params
const url = new URL(window.location.href);

//Check to see if the debug URL param exists
const  debugCheckString= url.searchParams.get("debug");
const debugUrlParamValue = debugCheckString === "true" ? true : false ;

//Check to see if the admin URL param exists
const  adminCheckString= url.searchParams.get("admin");
const adminUrlParamValue = adminCheckString === "true" ? true : false ;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App 
      debug={debugUrlParamValue}
      admin={adminUrlParamValue}
    />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
