




export const DebugComponent = ({
    debug,
    calendarToggleHandler,
    adminToggleHandler,
    editFormToggleHandler,
    newFormToggleHandler,
    loginToggleHandler,
}) => {
    if(debug){console.log("DebugComponent started")}
    
    return(
        <div>
            <div>
                <p>Debug Component</p>
            </div>
            <div>
                <input 
                    type="button" 
                    onClick={calendarToggleHandler}
                    value="toggle calendar visibility"
                />
            </div>

            <div>
                <input 
                    type="button" 
                    onClick={adminToggleHandler}
                    value="toggle Admin Component visibility"
                />
            </div>

            <div>
                <input 
                    type="button" 
                    onClick={loginToggleHandler}
                    value="toggle Login Component visibility"
                />
            </div>

            <div>
                <input 
                    type="button" 
                    onClick={editFormToggleHandler}
                    value="toggle Edit Item Form visibility"
                />
            </div>

            <div>
                <input 
                    type="button" 
                    onClick={newFormToggleHandler}
                    value="toggle New Item Form visibility"
                />
            </div>
        </div>
    );
}