import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
//import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import './calendar.css';
import {googleCalendarApiKey} from '../shared/secrets'
import userData from '../shared/users.json';
import * as utils from "../shared/utilities";

//const myGoogleCalendarApiKey = 'AIzaSyC4l_5bVMyUy6d3kC0VTYJg3KVaejuiB7o';

//const myGoogleCalendarId = 'u3uahk0nhrur6rlqprigfl0f14@group.calendar.google.com' ;
import googleCalendarPlugin from '@fullcalendar/google-calendar';

export const CampingCalendar = ({
    debug
}) => {
    

    const eventSourceArray = userData.users.map((user)=>{
        return({
            googleCalendarId:user.calendarId,
            backgroundColor:  user.backgroundColor,
            borderColor: user.borderColor,
            textColor: user.textColor
        });
    })
    if(debug){utils.writeToConsole("Debug Mode","Enabled",true);}
    if(debug){utils.writeToConsole("Users","object",userData);}
    if(debug){utils.writeToConsole("eventSourceArray","object",eventSourceArray);}

    return(
        <div className="CampingCalendar" title="CampingCalendar">
            <FullCalendar
                plugins={[ dayGridPlugin,googleCalendarPlugin  ]}
//                dateClick={handleDateClick as any}
                contentHeight={"auto"}
                googleCalendarApiKey={googleCalendarApiKey}
                eventSources={ eventSourceArray }          
            />
            <span style={{color:"white"}}>CampingCalendar</span>
        </div>
    );
}