
/* 
    Fields for new items:
    Location,
    Start date,
    End date,
    Available sites,
    People/tents per side,
    Camp site,
    (Cost?)
*/



export const NewItemForm = ({
    debug,
}) => {
    return(
        <div>
            <div>
                <p>New Item Form</p>


            </div>
        </div>
    );
}