//import logo from './logo.svg';
//import './App.css';
import {  useState } from 'react'

import {DebugComponent} from './DebugComponent/debug'
import {AdminComponent} from './AdminComponent/admin'
import {LoginComponent} from './LoginComponent/login'
import {CampingCalendar} from './CalendarComponent/calendar'
import {EditItemForm} from './EditItemForm/editItem';
import {NewItemForm} from './NewItemForm/newItem';

export function App({debug,admin}) {

  //const [userIsAuthorized, setUserAuthorized] = useState(false);
  const [showDebug, setDebugVisibility] = useState(debug === true ? true : false);
  const [showAdmin, setAdminVisibility] = useState(admin === true ? true : false);
  const [showLogin, setLoginVisibility] = useState(false);
  const [showCalendar, setCalendarVisibility] = useState(debug === true ? false : true);
  const [showNewItem, setNewItemVisibility] = useState(false);
  const [showEditItem, setEditItemVisibility] = useState(false);

  const calendarVisibilityHandler = () => {
    setCalendarVisibility(!showCalendar);
  }

  const adminVisibilityHandler = () => {
    setAdminVisibility(!showAdmin);
  }

  const newFormVisibilityHandler = () => {
    setNewItemVisibility(!showNewItem);
  }

  const editFormVisibilityHandler = () => {
    setEditItemVisibility(!showEditItem);
  }

  const loginVisibilityHandler = () => {
    setLoginVisibility(!showLogin);
  }

  if(1+1===11){setDebugVisibility();}

  return (
    <div className="App"> 

      { showDebug ?
        <DebugComponent
          debug={debug}
          calendarToggleHandler={calendarVisibilityHandler}
          adminToggleHandler={adminVisibilityHandler}
          editFormToggleHandler={editFormVisibilityHandler}
          newFormToggleHandler={newFormVisibilityHandler}
          loginToggleHandler={loginVisibilityHandler}
        /> : null }

      { showAdmin ? 
        <AdminComponent
          debug={debug}          
        /> : null }

      { showLogin ? 
        <LoginComponent
          debug={debug}          
        /> : null }

      { showCalendar ?
        <CampingCalendar
          debug={debug}
        /> : null }
      
      { showNewItem ?
        <NewItemForm
          debug={debug}
        /> : null }

      { showEditItem ?
        <EditItemForm
          debug={debug}
        /> : null }


       <span style={{color:'white'}}>Learn React</span>
    </div>
  );
}

export default App;
