




export const LoginComponent = ({
    debug
}) => {
    return(
        <div>
            <div>
                <p>Login Component</p>
            </div>
        </div>
    );
}