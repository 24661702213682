
export const onlyUnique = (value, index, self) => { // Return only unique values from a simple array
    return self.indexOf(value) === index ;
};

export const setCookie = (name, val, days) => {
    const date = new Date();
    const value = val;

    // Set it expire in 7 days
    //date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));
    date.setTime(date.getTime() + (days*24*60*60*1000));
    // Set it
    document.cookie = name+"="+value+"; expires="+date.toUTCString()+"; path=/";
}

export const getCookie = (name) => {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    
    if (parts.length === 2) {
        return parts.pop().split(";").shift();
    }
    else{
        return null;
    }
}

const deleteCookie = (name) => {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name+"=; expires="+date.toUTCString()+"; path=/";
}


export const writeToConsole = (title,message,data) => {
//https://www.samanthaming.com/tidbits/40-colorful-console-message/
const titleStyle = [
  'color: green',
  //'background: yellow',
  'font-size: 20px',
  //'border: 1px solid red',
  //'text-shadow: 2px 2px black',
  'padding: 5px',
].join(';'); // 2. Concatenate the individual array item and concatenate them into a string separated by a semi-colon (;)

const textStyle = [
  'color: blue',
  'font-size: 12px',

].join(';'); // 2. Concatenate the individual array item and concatenate them into a string separated by a semi-colon (;)

console.log('%c%s%c%s',titleStyle,title,textStyle,message,data);
};
